#dropdown-menu-content{
    left: -20px
}

.dash-navbar{
    
    border-bottom: 1px solid rgb(204, 205, 207);
}

.dropdown {
    position: relative;
    display: inline-block;
  }

.sidebar-button{
    background: transparent;
    border: transparent;
    
} 

.sidebar-button:hover{
    background-color: rgba(0, 0, 0, 0.04);
}

.sidebar-button:active{
    border-color: transparent;
}

.sidebar-button:focus{
    border-color: transparent;
    outline: transparent;
}

.sidebar-button-icon{
    height: 2rem !important;
    width: 2rem !important;
}
