/*Reusable*/

.floatLeft{
  float: left;;
}
.floatRight{
  margin-right: 45%;;
}
.false{
  display: none !important;
}
.true{
  display: inline-block;
}

.sameline{
  display: inline-block;
}
/*Quiz*/
.Quiz {
    position: relative;
    max-width: 95%;
    margin: auto;

}

.quiz-card-container {
    padding: 1rem;
    border-radius: 3px;
    background: #fff;
    text-align: left;
    box-shadow: 5px 5px 10px gray;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.quiz-question {
 text-align: left;
 margin-bottom: 1rem;
}

.quiz-answerOptions {
    margin: 0;
    padding: 0;
    list-style: none;
}

.answerOption{
    border-top: 1px solid #eee;
    min-height: 4rem;
    padding: 1rem;
}

.answerOption:hover{
    background-color: #eefbfe;
}

.answerOption.default {
  background-color: white !important;
}

.correct{
  background-color: lightgreen !important;
}

.correct-icon{
  fill: lightgreen !important;
}

.incorrect{
  background-color: lightcoral !important;
}

.incorrect-icon{
  fill:  lightcoral !important;
}

a {
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
  }
  
  a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .previous, .next, .submit, .retry,.feedback {
    background-color: #f1f1f1;
    color: black;
    border: none;
    padding: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    transition-duration: 0.2s;
  }

  .next{
    float: right;
  }
  .next.false{
    display: none !important;
  }
  .next.true{
    display: inline-block;
  }
  .submit{
    float: left;
  }


  .submit:hover {
    background-color: #d2d2d2;
   color: black;
  }

  .submit.true {
    display: inline-block;
  }

  .submit.false{
    display: none !important;
  }
  
  .retry.true{
    display: inline-block;
  }
  .retry.false{
    display: none !important;
  }
  .retry {
    float: left;
  }

  .nextTopic{
    width: 30%;
    background-color: #d2d2d2;
    color: black;
    border-radius: 2px;
    text-align: center;
    transition-duration: 0.2s;
  }
  
  .nextTopic:hover {
    background-color: #d2d2d2;
    color: black;
  }

  
  .nextTopic.true{
    display: inline-block;
  }
  .nextTopic.false{
    display: none !important;
  }
 

  .congra-container{
    margin-top: 3rem;
    width: 100%;
  }

  .round {
    border-radius: 50%;
  }

  .afterSubmit-container{
    width: 100%;
    transition-duration: 0.2s;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .popup-alert{
  
    max-width: 35%;
  }


/*Quiz explanation*/
.quiz-exp {


    background: #fff;
    text-align: left;

    margin-bottom: 2rem;
    margin-top: 1rem;
    width: 100%;
}

.exp-option-contaner{
  box-shadow: 5px 5px 10px gray;
  padding: 1rem;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  vertical-align: middle;
}
.exp-text-contaner{
  padding: 0.5rem 1rem;
  vertical-align: middle;
}

.buttons-bottom{
  margin:auto;
}

.congra-title{
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}
/*Text Editor*/
.text-container{
  min-height: 6rem !important;
}



/* Animation */
.quiz-card-container-enter {
  opacity: 0;
}

.quiz-card-container-enter.quiz-card-container-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.quiz-card-container-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.quiz-card-container-leave.quiz-card-container-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.quiz-card-container-appear {
  opacity: 0;
}

.quiz-card-container-appear.quiz-card-container-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}


/*Icon*/
.quizIcon-container{
  width: 5%;
  display: inline-block;
}
.quiz-exp-option-container{
  width: 95%;
  display: inline-block;
}

.quiz-question-image{
  width: 75%;
  height: 50%;
  display: inline-block;
}

.quiz-answer-image{
  width: 30%;
  height: 20%;
}
.underline {
  text-decoration: underline;
}

.quiz-question-image{
  width: 75%;
  height: 50%;
  display: inline-block;
}

.quiz-answer-image{
  width: 30%;
  height: 20%;
}
.underline {
  text-decoration: underline;
}