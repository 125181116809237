button img.Icon {
    opacity: 1.0;
    height: 28px;
    width: 28px;
  }
  
  audio:active, audio:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
  
  button:active, button:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
  
  .record button {
    margin: 5px;
  }
  
  button {
    height: 40px;
  }
  
  .record{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} */
  }

  .oscilloscope{
    margin: 1rem;
  }

  .bottomButtons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}