.header-background{
    background-image: linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,.3)),url('University-College.jpg');
    max-width:100%;
    max-height:100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    object-fit: cover;
}



.header-container{
    height: 40rem;
    background-repeat: no-repeat;

    width: 100%;
    margin-bottom: 0 !important;
}

.header-text-container{
    padding: 0 !important;
    margin-left: 10% !important;
}

.header-h1 {
    padding: 0 !important;
    opacity: 1;
    padding-top: 3rem !important;
}

.header-h1:hover{
    background-color: transparent;
}