
.btn { /* just for this demo. */
    margin-top: 5px;
}
.btn-arrow-right,
.btn-arrow-left {
    position: relative;
    padding-left: 18px;
}
.btn-arrow-right:focus,
.btn-arrow-right:focus {
    outline: none;
}
.btn-arrow-right {
    padding-left: 36px;
    border-radius: 3px;
}
.btn-arrow-left {
    padding-right: 36px;
    border-radius: 3px;
}
.btn-arrow-right:before,
.btn-arrow-right:after,
.btn-arrow-left:before,
.btn-arrow-left:after { /* make two squares (before and after), looking similar to the button */
    content:"";
    position: absolute;
    top: 9px; /* move it down because of rounded corners */
    width: 38px; /* same as height */
    height: 38px; /* button_outer_height / sqrt(2) */
    background: inherit; /* use parent background */
    border: inherit; /* use parent border */
    
    border-radius: 0px 4px 0px 0px; /* round arrow corner, the shorthand property doesn't accept "inherit" so it is set to 4px */
    -webkit-border-radius: 0px 4px 0px 0px;
    -moz-border-radius: 0px 4px 0px 0px;
}
.btn-arrow-right:before,
.btn-arrow-right:after {
    transform: rotate(45deg); /* rotate right arrow squares 45 deg to point right */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.btn-arrow-left:before,
.btn-arrow-left:after {
    transform: rotate(225deg); /* rotate left arrow squares 225 deg to point left */
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
}
.btn-arrow-right:before,
.btn-arrow-left:before { /* align the "before" square to the left */
    left: -18px;
}
.btn-arrow-right:after,
.btn-arrow-left:after { /* align the "after" square to the right */
    right: -18px;
}
.btn-arrow-right:after,
.btn-arrow-left:before { /* bring arrow pointers to front */
    z-index: 1;
}
.btn-arrow-right:before,
.btn-arrow-left:after { /* hide arrow tails background */
    background-color: white;
}