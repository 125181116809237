.parent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.outer3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background-color: #7270a123;
  width: 100%;
  height: 100%;
}

.all-page-container {
  height: 100%;
  max-height: 80vh;
  overflow: auto;
}

.pdfAndButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.pdf {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.outer {
  display: flex;
  flex-direction: column;
}

.pagintationOuter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  height: 200px;
}

/* .react-pdf__Page__textContent span {
	opacity: 0.1;
}

.react-pdf__Page__textContent span::selection {
	background-color: blue;
}

.react-pdf__Document {
	line-height: initial;
} */
