.guessable {
  background: #a8d1ff;
}

.unguessable {
  background: #fff2a8;
}

.vocabbox {
  margin: 100px;
  padding: 1rem;
  border-radius: 3px;
  background: #fff;
  text-align: left;
  box-shadow: 5px 5px 10px gray;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.wordbox {
  display: flex;
  flex-direction: row;
}
.words {
  width: 150%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 960px) {
  .wordbox {
    display: flex;
    flex-direction: column;
  }
}

.borderBox {
  border: 1px black;
}

.radioClass {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
}

.color-blue {
  background-color: rgba(57, 142, 170, 0.3);
  border-radius: 20px;
  padding-right: 13px;
  margin-left: 2rem;
  /* padding-left: 13px;
  padding-top: 10px; */
}

.color-yellow {
  background-color: rgba(190, 187, 0, 0.3);
  border-radius: 20px;
  padding-right: 13px;

}

.border{
  width: 80%;
}
