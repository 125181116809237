.Title {
    margin-bottom: 32px;
    color: #555;
    align-items: center;
  }
  
  .outputWrapper {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .cardWrapper {
    height: 100%;
    width: 100%;
  }