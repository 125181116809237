.ast {
    background: none;
    color:red;
  }

.login-image{
    background-image: linear-gradient(rgba(196, 196, 196, 0.44), rgba(196, 196, 196, 0.44)),url('../../../styles/global/University-College.jpg' );
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    object-fit: cover;

    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    
  }

  .login-image-inner{
    width: 75%;
    height: 75%;
    background: rgba(0, 32, 78, 0.45);
    color: white;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    text-align: center;
    padding: 1rem;
    padding-top: 2rem;
    overflow: hidden;
  }

.login-image-inner a{
  color: white;
  font-weight: 600;
}

.login-image-inner a:hover{
  color: white;
  background-color: transparent;
  border: 1px white;
}
.login-right{
  height: 100%;
  max-height: 100%;
  padding-left: 0px !important;
}
  .login-form{
   padding: 2rem;
  }

  .login-form .input-group{
    height: 3rem;
    margin-bottom: 1.5rem;
  }

  .login-form .input-group input{
    height: 100%;
  }

  .login-logo{
    padding: 1rem !important;
    padding-left: 1.5rem !important;
    height: 20%;
    max-height: 20%;
  }
  .login-logo img{
    max-height: 100%;
    max-width: 100%;
  }
  .uoft-container{
    max-height: 15%;
    padding: 1rem;
    padding-left: 1.5rem;
    background: rgba(0, 32, 78);
    color: white;
    text-align: left;
  }
  .login-button{
    max-width: 40%;
    width: 20%;
  }

.password-reset-container {
    
    max-width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(77, 75, 75);
  }

.password-reset-form{
  max-width: 100%
}
