
html,body, #root {
    height: 100%;
}

/*Login Page*/
.home-container{
    background-image: url('University-College.jpg');
    background-position: center center;
    min-height: 100%;  
    min-width: 100%;
    width: 100%;
    z-index: -1;
    background-size: cover;
    object-fit: cover;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(20px);
    position: absolute;
    overflow: hidden;
}


.login-type-container{
    margin-top: -20rem;
    background: rgba(255,255,255,0.97);
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 8rem;
    display: block;
    height: 40%;
    display: inline-block;
    -webkit-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.28);
    box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.28);
    display: block;
    max-width: 80%;
    padding: 3rem;
    z-index: 10;
    position: relative;
    border-radius: 0.5rem;
}

.login-container-position{
    transform: translate(0%, 25vh);
    min-width: 75%;
    max-height: 90%;
    height: 50%;
}

.login-container{

    z-index: 10;

    display: block;
    position: relative;
    
    height: 100%;
    min-width: 100%;
    margin:auto;
    padding: 0px !important;
    /* margin-bottom: 4rem; */
    max-width: 80%;

    overflow-y: hidden;
    overflow-x: hidden;

    background: white;
    border-radius: 0rem;
    
    
    -webkit-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.28);
    box-shadow: 6px 10px 4px rgba(0, 0, 0, 0.25) !important;
  
}

/*Footer*/
.footer-container{
    color: white;
    margin: -15px;
    min-width: 100%;
}

.stay-bottom{
    position:fixed;
    bottom: 0;
}

/*Sidebar*/
.sidebar_open{
    margin-left: 0 !important;
}
  
.sidebar_close {
    margin-left: -9rem !important;
}

.body_right{
    margin-left: 9rem !important;
}
  
.body_left {
    margin-left: 0 !important;
}

#wrapper {
    transition:  0.25s ease-out;
}

.sidebar-indi{
    border-left: 5px solid white !important;
    background-color: rgba(0, 0, 0, 0.04);
}

.sidebar-indi-none{
    border-left: none;
    background-color: none;

}


/*Quiz*/
.print-icon{
    padding: 0px;
    margin-left: 5px;
    max-height: 2rem ;
}

.print-icon:focus{
    text-decoration: none;
    border: transparent;
    outline: transparent;
}


/*FAQ*/
.panel-title {
    background-color: #eeeeee !important;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.panel-title p{
    font-weight: bold;
}

.panel-container{
    
}

.panel-each{
    margin-bottom: 1rem !important;
    box-shadow: none !important;
}

.MuiExpansionPanelDetails-root{
    padding-bottom: 8px !important;
}

/*GrowElement*/
.grow-container{
    /* padding: 1rem; */
    margin-bottom: 1rem;
}

.action-container{
    width: 70%;
}
.action-title{
    display: inline !important;
    width: 100%;
}
.action-label{
    display: inline-block;
    max-width: 90%;
    padding-left: 15px;
    font-size: 1.5em;
    font-weight: bold;
}
.action-component-title{
    display: inline-flex;
    width: 100%;
}
.action-toggle-button{
    float: right;
    min-width: 10%;
    max-width: 50%;
}