#side-bar-container{
  position: fixed;
  width: 11rem;
  height: 100%;
  left: 0;
}

#sideBar{
  width: 9rem;
  height:100%;
  position: fixed;
  overflow: auto;
  margin-left: -9rem;
  background-color: #43425D;
  transition:  0.25s ease-out;
  left: 0;
  display: inline-block;

}

/* #sideBar.show{
  margin-left: 0;
}
#sideBar.hide {
  margin-left: -9rem;
} */

@media (max-width: 768px) {
  #sideBar {
    margin-left: 0; 
  }

  #sideBar.hide {
    margin-left: 0rem;
  }
  #sideBar.show {
    margin-left: -9rem;
  }
  #sideBarButton {
  left: 0;
  }
  #sideBarButton.hide{
    right:0 !important;
    left: 0 !important;
  }
  #sideBarButton.show {
    left: -9rem !important;
  }

}

#sideBarButton.hide{
  left: -9rem;
}

#sideBarButton.show {
  right:0;
}

#sideBarButton {
  border-radius: 0 5px 5px 0;
  background-color: #717084;
  border: 0;
  position: relative;
  float: right;
  left:0;
  right: auto;
  margin-top: 1rem;
  transition:  0.25s ease-out;
}

#sideBarButton:focus{
  outline: 0;
}


.side-bar-item-list{
  display: inline !important;
}

.side-bar-item-container {
  height: auto;
  position: relative;
  margin-bottom: 1rem;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  

}
.side-bar-item {
  display: block !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.side-bar-icon {
  fill: white;
  width: 30%;
}

.side-bar-text {
  color: white ;
}

.center-align svg,.center-align span,.center-align img{
    display: block;
    margin: auto;
    text-align: center;
}

.hover-none{
  padding: 0;
  width: 100%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.hover-none:hover{
  background-color: transparent;
  text-decoration: none;
}

.hover-none:active {
    text-decoration: none;
}

