.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  
  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .Files {
    align-items: flex-end;
    justify-items: flex-end;
    flex: 1;
    overflow-y: auto;
  }
  
  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 32px;
  }
  
  
  
  .Filename {
    /* margin-bottom: 8px; */
    font-size: 16px;
    color: #555;
    height: 100%;
    align-items: center;
    display: flex;
  }
  
  .Row {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    /* justify-content: space-between; */
    height: 50px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .CheckIcon {
    opacity: 0.5;
    margin-left: 5px;
    height: 100%;
  }
  
  .ProgressWrapper {
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    height: 100%;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  
  button {
    font-family: "Roboto medium", sans-serif;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    background: "#7270a1";
    color: #fff;
    border-color: "rgba(0, 32, 78)";
    outline: 0;
    margin-right: "10px";
  }
  
  button:disabled {
    background: rgb(189, 189, 189);
    cursor: default;
  }
  
  .Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
  }