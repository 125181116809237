.liStyles::before {
    content: "✘"; /* Insert content that looks like bullets */
    padding-right: 2px;
    color: red; /* Or a color you prefer */    
}

.uiStyles{
    list-style: none;
}

