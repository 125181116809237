.outer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background-color: #7270a123;
  min-height: 40vh;
}

.box {
  justify-content: center;
  flex-direction: column;
  background-color: #7270a148;
  margin: 1rem;
  width: 50%;
}

.feedback {
  background-color: #f1f1f1;
  color: black;
  border: none;
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition-duration: 0.2s;
}

.floatRight {
  margin-right: 45%;
}

.innerText {
  margin: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
}
