.removed {
  background-color: #ffcccc;
}
.added {
  background-color: #c6f6d5;
  font-weight: bold;
}
.highlight {
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  transition: background 0.2s ease-in-out;
}
.highlight:hover {
  opacity: 0.7;
}
.important {
  background-color: #ff6b6b;
}
.question {
  background-color: #fbc02d;
}
.clarification {
  background-color: #66bb6a;
}
.note {
  background-color: #42a5f5;
}
.myButton {
  background-color: #4CAF50; /* green */
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
.myButton:hover {
  background-color: #43a047; /* a darker green */
}