.root{
    width: 75em;
    height: 50em;
    background-color: white;
    color: #30466E !important; 
}

.header{
      width: 100%;
      height: 25%;
      background-color: #30466E;
      color: #C09527;
}
.headerText {
      padding-top: 3%;
  }

.bodyText {
      padding-top: 5%;
  }